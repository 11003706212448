import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { buildUrl } from "../../../services";

/**
 * @param { {
 *   id: number,
 *   fecha: string,
 *   cliente: string,
 *   cliente_id: string,
 *   cedula: string,
 *   medida_inicial: string,
 *   medida_final: string,
 *   total_medida: string,
 *   contador_id: string,
 *   firma?: string,
 *   plaza: string,
 *   plaza_id: string,
 *   usuario: string,
 *   unidad_medida: string,
 * } props
 *
 */
function conduce(props) {
  const param = props;

  function formatNumber(num) {
    return Number(num).toLocaleString("es-DO", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  
  const clienteText = `Cliente: ${param.cliente?.cliente}`;
  const atendidoPor = `Atendido Por: ${param.usuario}`;
  const plaza = `Plaza: ${param.plaza.plaza_id}-${param.plaza.plaza.toUpperCase() || ""}`;
  const empresa = {
    rnc: "101033738",
    company: "PROPANO Y DERIVADOS S.A.",
    telefono: "809-364-1000 Opción 1",
    web: "www.grupopropagas.com",
    email: "servicioalcliente@propagas.do",
  };

  const options = {
    format: [110, 300],
  };

  var doc = new jsPDF(options);
  //autoTable(doc, { html: '#tabla-conduce' })
  var docWidth = doc.internal.pageSize.width;
  var marginX = 1;
  var currentHeight = 10;
  var pdfConfig = {
    headerTextSize: 20,
    subHeaderTextSize: 18,
    labelTextSize: 16,
    fieldTextSize: 14,
    lineHeight: 6,
    subLineHeight: 4,
  };

  //var docHeight = doc.internal.pageSize.height;

  var colorBlack = "#000000";
  //var colorGray = "#4d4e53";
  //starting at 15mm

  var marginY = doc.internal.pageSize.width - 30;

  //var startPointRectPanel1 = currentHeight + 6;

  doc.setFontSize(pdfConfig.headerTextSize);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(colorBlack);

  doc.text(docWidth / 16 - 3, currentHeight, empresa.company || "");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  // RNC
  doc.setFont("helvetica", "bold");
  doc.setFontSize(pdfConfig.labelTextSize);
  doc.text(docWidth / 4 + 2, currentHeight, `RNC: ${empresa.rnc || ""}`);
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  /**Teléfono */
  doc.setFont("helvetica", "bold");
  doc.text(docWidth / 4 - 16, currentHeight, "Teléfono: ");
  doc.setFont("helvetica", "normal");
  doc.text(marginY - 42, currentHeight, empresa.telefono || "");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  /**Página Web */
  doc.setFont("helvetica", "bold");
  doc.text(docWidth / 4 - 12, currentHeight, "Web: ");
  doc.setFont("helvetica", "normal");
  doc.text(marginY - 49, currentHeight, empresa.web || "");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  /**Email */
  doc.setFont("helvetica", "bold");
  doc.text(docWidth / 4 - 18, currentHeight, "Email: ");
  doc.setFont("helvetica", "normal");
  doc.text(marginY - 52, currentHeight, empresa.email || "");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFontSize(pdfConfig.subHeaderTextSize);
  doc.setFont("helvetica", "bold");
  doc.text("Conduce", docWidth / 4 + 20, currentHeight, {
    align: "center",
    lang: "es",
  });

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  /**Documento */
  doc.setFont("helvetica", "bold");
  doc.setFontSize(pdfConfig.labelTextSize);
  doc.text(
    marginX,
    currentHeight,
    "Documento: " +
      "PROG-" +
      param.cliente.cliente_id +
      "-" +
      param.id.toString() || ""
  );
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  /** Fecha Emisión de Documento */
  doc.setFont("helvetica", "bold");
  doc.text(
    marginX,
    currentHeight,
    "Fecha: " + new Date().toLocaleString("es-DO")
  );
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFontSize(pdfConfig.subHeaderTextSize);
  doc.setFont("helvetica", "bold");
  doc.text("Totalizador", docWidth / 4 + 20, currentHeight, {
    align: "center",
    lang: "es",
  });
  currentHeight += pdfConfig.subLineHeight;

  const colums = ["Medidor", "Inicio", "Fín", "Dif."];
  const rows = [
    param.contador_id || "",
    Number(param.medida_inicial).toLocaleString("es-DO", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) || "",
    Number(param.medida_final).toLocaleString("es-DO", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) || "",
    Number(param.total_medida).toLocaleString("es-DO", { minimumFractionDigits: 0, maximumFractionDigits: 0 }) || "",
  ];

  const headerStyles = {
    fillColor: [240, 240, 240],
    textColor: [0],
    fontFamily: "helvetica",
    fontStyle: "bold",
  };

  doc.autoTable({
    head: [colums],
    body: [rows],
    startY: currentHeight,
    tableWidth: "auto", // Permite que la tabla ocupe todo el ancho disponible
    headStyles: {
      fillColor: headerStyles.fillColor,
      textColor: headerStyles.textColor,
      fontStyle: headerStyles.fontStyle,
      fontFamily: headerStyles.fontFamily,
      fontSize: 16,
      halign: "center",
      border: true,
      lineColor: 10,
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { cellWidth: "auto" },
      1: { cellWidth: "auto" },
      2: { cellWidth: "auto" },
      3: { cellWidth: "auto" },
    },
    bodyStyles: {
      fontSize: 16,
      fontStyle: "bold",
      font: "helvetica",
      cellPadding: 2,
      textColor: [0, 0, 0],
      halign: "center",
      border: true,
      lineColor: 10,
      lineWidth: 0.5,
    },
    margin: { left: 2, right: 2 }, // Reducimos los márgenes laterales
  });

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFontSize(pdfConfig.labelTextSize);
  doc.setFont("helvetica", "bold");
  doc.text(marginX, currentHeight, "Producto:");
  doc.text(marginY, currentHeight, "GLP(E)");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  if (param.unidad_medida.toUpperCase() === "M3") {
    doc.text(marginX, currentHeight, "Cantidad: (M3)");
    doc.text(marginY, currentHeight, formatNumber(param.total_medida) || "");
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    doc.text(marginX, currentHeight, "Total Galones: ");
    doc.text(marginY, currentHeight, formatNumber(param.total_medida * 1.2) || "");
  } else {
    doc.text(marginX, currentHeight, "Total Galones:");
    doc.text(marginY, currentHeight, formatNumber(param.total_medida * 1.2) || "");
  }

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  /**Información de la Plaza
   * Nombre de la Plaza
   */
  /**Fecha de toma de la lectura */
  doc.setFont("helvetica", "bold");
  doc.text(
    marginX,
    currentHeight,
    "Fecha de Lectura: " + new Date(param.fecha).toLocaleDateString("es-DO") ||
      ""
  );
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  doc.setFontSize(pdfConfig.fieldTextSize);
  doc.setFont("helvetica", "bold");
  const wrappedPlaza = doc.splitTextToSize(plaza,docWidth - 10);
  doc.text(marginX, currentHeight, wrappedPlaza);
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  /**Código del cliente */
  doc.setFont("helvetica", "bold");
  doc.text(marginX, currentHeight, "Código: " + param.cliente.cliente_id || "");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  /**Nombre del cliente */
  doc.setFont("helvetica", "bold");
  const wrappedClienteText = doc.splitTextToSize(clienteText, docWidth - 10);
  doc.text(marginX, currentHeight, wrappedClienteText);
  currentHeight += pdfConfig.subLineHeight;

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  if (param.firma !== undefined) {
    doc.addImage(
      buildUrl("/file", { id: param.firma }),
      "PNG",
      marginX,
      currentHeight,
      90,
      16,
      "signature",
      "NONE",
      0
    );
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(pdfConfig.subHeaderTextSize);
    doc.text(docWidth / 4 + 8, currentHeight, "Recibido Por");
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(pdfConfig.labelTextSize);

    doc.text(docWidth / 4 + 8, currentHeight, param.cliente.cedula || "");
    currentHeight += pdfConfig.subLineHeight;
    doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(pdfConfig.subHeaderTextSize);
    doc.text(docWidth / 4 + 10, currentHeight, "Cédula");
  }

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  const wrappedAtendidoText = doc.splitTextToSize(atendidoPor, docWidth - 10);
  doc.text(marginX, currentHeight, wrappedAtendidoText);

  return doc;
}

export default conduce;
